import * as React from "react";
import { Link } from "gatsby";

// markup
const NotFoundPage = () => {
  return (
    <main className="h-screen w-screen flex justify-center items-center">
      <div className="max-w-content mx-auto flex-1 px-4 md:px-8">
        <h1 className="text-3xl font-bold font-mono">404</h1>
        <p>
          That page could not be found.
          <br />
          Please check your spelling, try again or{" "}
          <Link to="/" className="underline">
            go home
          </Link>
        </p>
      </div>
    </main>
  );
};

export default NotFoundPage;
